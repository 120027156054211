.bot-left{
    
    /* height: 100%;
    padding-left: 100px; */
    /* text-align: center; */
    /* margin-left: 30px;
    margin-bottom: 30px; */
    margin-bottom: 60px;
    
   }
.centered{
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* width: 100%; */
    align-self: center;
    
}
body{
    
    

}
.blur{
   
    /* filter: blur(8px);
    -webkit-filter: blur(2px); */
    max-height: 100vh;
    background-image: url('back.jpg');
    background-size: cover;
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: -1;
}

.App{
     /* background-color: black;
      */
    color: white;
    max-height: 100vh;
    height: 90vh;
    /* text-shadow: 3px 3px 13px rgba(150, 150, 150, 1); */
    text-shadow: 5px 4px 9px rgba(255, 255, 255, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
     
}
.navCont{
    /* display: flex; */
    /* justify-content: flex-end; */
    align-self: flex-end;
    /* filter: blur(8px);
    -webkit-filter: blur(10px); */
    /* -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px); */
}
/* .navBlur{
    position: fixed;
    width: 100%;
    height: 100%;
    filter: blur(8px);
    -webkit-filter: blur(8px);
} */
.nav{
   margin-top: 30px;
    margin-top: 30px;
    /* position: fixed; */
    display: flex;
    
}
.navBtn{
    /* color: green; */
    margin-right: 40px;
    padding: 20px 30px;
    border-color: white;
    border-style: solid;
    border-width: 2px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, .2)
    /* animation-name: color-mirror;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
    animation-duration: 6s; */
}
#name, #career{
    cursor:pointer;
    /* width: 200px; */
    /* display: inline-block; */
}

#name{
    animation-name: bhl;
    animation-iteration-count: infinite;
    /* animation-direction: alternate; */
    animation-timing-function: linear;
    animation-duration: 4s;

}
#career {
    animation-name: bhl;
    animation-iteration-count: infinite;
    /* animation-direction: alternate; */
    animation-timing-function: linear;
    animation-duration: 3s;
    animation-delay: 1s;
}
.blog{
    /* This may be able to handle application in the parent container to all divs in center rather
    than each child component such as blog, resume (possibly changed to portfolio) etc */
    /* background-color: rgba(63, 127, 191, .5); */
    background-color: rgba(114, 127, 140, .5);
    padding: 50px;
    margin-top: 20px;
    margin-bottom: 0px;

    
    overflow-y: scroll;
    max-height: 40vh;
    
}
.data{
     background-color: rgba(114, 127, 140, .9);
     padding: 20px;
   
}
.outer{
    margin: 60px;
    position: absolute;
    border-style: solid;
    border-color: rgba(255, 255, 255, .9);
    border-width: 2px;
    /* animation-name: bhl;
    animation-delay: 1s;
    animation-timing-function: linear;
    animation-duration: 2s;
    animation-direction: normal;
    animation-iteration-count: infinite; */

}
.hlCont{
    position: relative;
    /* margin-bottom: 230px; */
    margin-bottom: 20px;
    top: -60px;
}
@keyframes bhl {
    to{
        padding: 60px;
        margin: 0px;
        border-color: rgba(255,255,255,0);
        border-radius: 10px;
        /* border-width: 0px; */
        
    }
}
@keyframes mbhl {
    to{
        margin: 0px;
        padding: 60px;
        padding-right: 0px;
        border-color: rgba(255,255,255,0);
        border-radius: 10px;
    }
        
    }


@keyframes hl {

    to{
        outline-offset: 150px;
        outline-color: rgba(0,0,0,0);
    }
}
@keyframes color-mirror {
    
    to{
        color: white;
       border-color: green;
    }
}
@media only screen and (max-width: 800px){
    .navBtn{
        padding: 10px 10px;
        margin: 3px;
    }
    .navCont{
        align-self: center;
    }
    .outer{
        margin-right: 0px;
    }
    #name{
    animation-name: mbhl;
    animation-iteration-count: infinite;
    /* animation-direction: alternate; */
    animation-timing-function: linear;
    animation-duration: 4s;

    }
    #career {
    animation-name: mbhl;
    animation-iteration-count: infinite;
    /* animation-direction: alternate; */
    animation-timing-function: linear;
    animation-duration: 3s;
    animation-delay: 1s;
}
    
}
